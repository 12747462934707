<template>
    <div class="ThreeDsInd">
        <el-tag
            effect="dark"
        >
            工业动画
        </el-tag>
        <p class="p justify">
            与传统的拍摄方法相比，工业动画具有更灵活的表现形式、更强大的说服力和更生动的效果。
        </p>
        <ul class="solution-content-ul justify">
            <li>可服务于研发、制造、销售多个阶段与场景；</li>
            <li>提供工作原理模拟、生产流程演绎、产品展示等；</li>
            <li>网站宣传、展会展览、业务交流的生动展示工具。</li>
        </ul>
        <p class="p justify">
            此外，工业动画更深的一层意义在于它可以用于后期的仿真模拟。我们采用三维真实建模，模型包含的是产品全部的信息，可以配合仿真模拟软件，将产品的生产状况、比如应力、电场、流速、温度等因素的分布计算出来，供实验参考和分析，从而可以加强数据采集、分析、处理能力，节约测试成本，解决实际的生产问题，减少决策失误，提升生产良率和器件性能。
        </p>
        <b-row>
            <b-col
                lg="6"
                cols="12"
            >
                <video
                    class="video-info"
                    controls="controls"
                    poster="@/assets/video/video2.png"
                >
                    <source
                        src="@/assets/video/redsun.mp4"
                        type="video/mp4"
                    >
                </video>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
  name: 'ThreeDsInd',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '三维可视化解决方案 - 生产制造方向')
      }
}
</script>
<style lang="less" scoped>
.ThreeDsInd{
    margin-bottom:6.25rem;
}
</style>
